
import { ref, onBeforeMount, onMounted } from 'vue';

const __default__ = {
  name: 'GMapStreetView',
  props: {
    coordinates: { lat: Number, lng: Number },
    query: { default: null },
    height: { default: '400px' },
    width: { default: '100%' },
  },
  setup(props) {
    const mapPanoRef = ref(null);

    const initialStreetView = (data) => {
      const coordinates = (props as any).coordinates;
      const pano = new (window as any).google.maps.StreetViewPanorama(
        mapPanoRef.value,
        {
          position: coordinates,
        }
      );

      const pov = pano.getPov();
      pano.setPov(pov);
    };

    // const initMap = () => {
    //   new (window as any).google.maps.StreetViewService().getPanorama({ location: coordinates }).then(({data}) => {
    //     console.log(data);
    //     initialStreetView(data);
    //   })
    // }

    return {
      mapPanoRef,
      initialStreetView,
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "15da145a": (_ctx.width),
  "d9066a8c": (_ctx.height)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__