
import { defineComponent } from 'vue';
import { numberFormat } from '@/utils/text';
import timeUtil from '@/utils/time';
import HistoryItem from '@/views/new-design/pages/Tenants/ViewReference/HistoryItem.vue';
import StreetViewModal from '@/views/new-design/pages/Tenants/ViewReference/StreetViewModal.vue';

export default defineComponent({
  name: 'rental-review-list-section',
  emits: ['selectRentalHistory'],
  components: {
    HistoryItem,
    StreetViewModal,
  },
  data() {
    return {
      loadingList: true,
      selectedCoordinates: {} as any,
      showingStreetView: false,
    };
  },
  props: {
    currentTenantReview: {
      type: Array,
      default: () => undefined,
    },
    tenantReviews: {
      type: Array,
      default: () => {
        return [];
      },
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    hasTenantReviews() {
      return typeof this.tenantReviews !== 'undefined'
        ? this.tenantReviews.length
        : false;
    },
  },
  watch: {
    selectedCoordinates: {
      handler() {
        this.$nextTick(() => {
          (this.$refs.streetviewmodal as any).initializeStreetView();
        });
      },
      deep: true,
    },
  },
  methods: {
    // steetViewAlt(event){
    //   event.target.src = require('@/assets/new-design/images/property-default-image.png');
    // },
    // isCurrent(review) {
    //   return this.$route.params.tenant_ref_id == review.reference_no;
    // },
    // selectRentalHistory(referenceNo) {
    //   this.$emit('selectRentalHistory', referenceNo);
    // },
    // dateFormat(date) {
    //   return timeUtil.dateFormat(date);
    // },
    // toDecimal(number) {
    //   return numberFormat(number, 1);
    // },
    // getAddress(review) {
    //   return `${review?.address_line}, ${review?.city}, ${review?.post_code}, ${review?.state}`;
    // },
    // getDates(review) {
    //   return `${this.dateFormat(review.move_in_date)} - ${this.dateFormat(
    //     review.move_out_date
    //   )}`;
    // },
    expandStreetView(coordinates: any) {
      this.selectedCoordinates = coordinates;
      this.showingStreetView = true;
    },
    selectRentalHistory(referenceNo) {
      this.$emit('selectRentalHistory', referenceNo);
    },
  },
});
