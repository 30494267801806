import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_TenantReview = _resolveComponent("TenantReview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Breadcrumb, { items: _ctx.breadcrumbs }, null, 8, ["items"]),
    (_openBlock(), _createBlock(_component_TenantReview, {
      key: _ctx.$route.path
    }))
  ]))
}