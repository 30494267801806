
import { defineComponent } from 'vue';
import Breadcrumb from '@/views/new-design/layout/Breadcrumb.vue';
import TenantReview from '@/views/new-design/pages/Tenants/ViewReference/Index.vue';

export default defineComponent({
  components: { Breadcrumb, TenantReview },
  data() {
    return {
      breadcrumbs: [],
    };
  },
  mounted() {
    console.log(this.$route);
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      if (from.name == 'list-references') {
        vm.breadcrumbs = [
          {
            to: '/references',
            text: 'Tenant References',
            current: false,
          },
          {
            to: `/tenants/${vm.$route.params.tenant_id}/${vm.$route.params.tenant_ref_id}`,
            text: 'Tenant Report',
            current: true,
          },
        ];
      } else if (from.name == 'view-agency') {
        vm.breadcrumbs = [
          {
            to: '/agencies',
            text: 'Agencies List',
            current: false,
          },
          {
            to: from.fullPath,
            text: 'Agency Details',
            current: false,
          },
          {
            to: `/tenants/${vm.$route.params.tenant_id}/${vm.$route.params.tenant_ref_id}`,
            text: 'Tenant Report',
            current: true,
          },
        ];
      } else if (from.name == 'list-requested-references') {
        vm.breadcrumbs = [
          {
            to: '/requested-references',
            text: 'Requested Tenant References',
            current: false,
          },
          {
            to: `/tenants/${vm.$route.params.tenant_id}/${vm.$route.params.tenant_ref_id}`,
            text: 'Tenant Report',
            current: true,
          },
        ];
      } else {
        vm.breadcrumbs = [
          {
            to: '/tenants',
            text: 'Tenant List',
            current: false,
          },
          {
            to: `/tenants/${vm.$route.params.tenant_id}`,
            text: 'Tenant Details',
            current: false,
          },
          {
            to: `/tenants/${vm.$route.params.tenant_id}/${vm.$route.params.tenant_ref_id}`,
            text: 'Tenant Reference',
            current: true,
          },
        ];
      }
    });
  },
});
