
import { defineComponent, onMounted } from 'vue';
import Header from '@/views/new-design/pages/Tenants/ViewReference/Header.vue';
import History from '@/views/new-design/pages/Tenants/ViewReference/History.vue';
import Report from '@/views/new-design/pages/Tenants/ViewReference/Report.vue';
import AgentDetails from '@/views/new-design/pages/Tenants/ViewReference/AgentDetails.vue';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import { mapActions, mapGetters } from 'vuex';
import { Getters, Actions, Mutations } from '@/store/enums/TenantReviewEnums';
import { TenantReview } from '@/models/TenantReviewModel';
import { Tenant } from '@/models/TenantModel';
import { numberFormat } from '@/utils/text';
import store from '@/store';
import {
  Actions as TActions,
  Getters as TGetter,
} from '@/store/enums/TenantEnums';
import BusinessLogo from '@/components/logo/BusinessLogo.vue';
import NoReview from '@/views/new-design/pages/Tenants/ViewReference/NoReview.vue';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import {
  Actions as StoreActions,
  Getters as StoreGetters,
} from '@/store/enums/StoreEnums';
import useBreakpoints from 'vue-next-breakpoints';

const initialValues = {
  id: '',
  tenant_id: '',
  tenant: {} as Tenant,
  reference_no: '',
} as {} as unknown as TenantReview;

export default defineComponent({
  components: {
    History,
    Report,
    AgentDetails,
    BusinessLogo,
    NoReview,
    Header,
  },
  data: () => ({
    currentTenantReview: initialValues,
    currentRefNo: '' as string,
    selectedRefNo: '' as string,
    routeRefNo: '' as string | string[],
    tenantId: '' as string | string[],
    loading: false,
    reviewDetailLoading: false,
    averageRating: '0',
    tenant: {} as unknown as Tenant,
    tab: 'report',
    logoData: null,
    showingHistory: true,
    showingInfo: true,
    windowWidth: window.innerWidth,
  }),
  async mounted() {
    // show page loading
    setTimeout(() => {
      store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 500);

    this.loading = true;
    this.reviewDetailLoading = true;
    setCurrentPageTitle('Get Tenant Reference');

    this.setRouteRefNo();

    await this.loadTenantReviews();
    await this.loadTenantReviewByRefNo();

    this.setTenantId();
    this.setTenant();
    this.computeAverageRating();
    this.fetchCurrentReviewAvatar();

    this.setSectionVisibility();

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  computed: {
    breakpoints() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoints as any)?.mobile?.matches;
    },
    isDesktop() {
      return (this.breakpoints as any)?.desktop?.matches;
    },
    businessLogoSrc() {
      if (!(this.tenantReview as any)?.user?.business_logo) return;

      return (this.tenantReview as any)?.user?.business_logo_src ?? '';
    },
    ...mapGetters({
      tenantReview: Getters.GET_TENANT_REVIEW,
      tenantReviews: TGetter.GET_TENANT_REFERENCES,
      getTenant: TGetter.GET_TENANT,
      authUser: AuthGetters.GET_AUTH_USER,
      defaultBGColor: StoreGetters.GET_DEFAULT_BACKGROUND_COLOR,
      defaultTextColor: StoreGetters.GET_DEFAULT_TEXT_COLOR,
    }),
    getBusinessLogoSrc() {
      return this.tenantReview?.user?.business_logo_src;
    },
    tenantRecord() {
      return this.tenant;
    },
    currentAddress() {
      return `${(this.tenantReview as any)?.address_line}, ${
        (this.tenantReview as any)?.city
      }, ${(this.tenantReview as any)?.post_code}, ${
        (this.tenantReview as any)?.state
      }`;
    },
    headerColor() {
      if (!(this.tenantReview as any)?.user?.business_color) {
        return '#4070ea';
      }

      return (this.tenantReview as any)?.user?.business_color ?? '#4070ea';
    },
    hasLogo() {
      return (
        (this.tenantReview as any)?.user?.business_logo &&
        (this.tenantReview as any)?.user?.business_logo.trim() != ''
      );
    },
    currentCoordinates() {
      const latitude =
        typeof this.tenantReview?.latitude !== 'undefined'
          ? this.tenantReview?.latitude
          : null;
      const longitude =
        typeof this.tenantReview?.longitude !== 'undefined'
          ? this.tenantReview?.longitude
          : null;

      return latitude && longitude
        ? {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          }
        : null;
    },
  },
  watch: {
    tenantReview: {
      handler(value) {
        store.commit(Mutations.SET_AGENT_AVATAR, '');

        if (value) {
          this.displayLogo();
          this.fetchAgentAvatar();
        }
      },
      deep: true,
    },
    '$route.params.tenant_ref_id': {
      handler() {
        this.loadTenantReviewByRefNo();
      },
      deep: true,
    },
  },
  beforeMount() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;

      if (this.windowWidth > 800) {
        this.showingInfo = true;
        this.showingHistory = true;
      } else {
        this.showingInfo = false;
        this.showingHistory = true;
      }
    },
    setSectionVisibility() {
      if ((this.breakpoints as any)?.mobile?.matches) {
        this.showingInfo = false;
      }

      if ((this.breakpoints as any)?.desktop?.matches) {
        this.showingInfo = true;
      }
    },
    back() {
      if ((this.breakpoints as any)?.mobile?.matches) {
        this.showingInfo = false;
      }

      if ((this.breakpoints as any)?.desktop?.matches) {
        this.showingInfo = true;
      }

      this.showingHistory = true;
    },
    ...mapActions({
      fetchTenantReviews: TActions.FETCH_TENANT_REFERENCES,
      fetchTenantReview: Actions.FETCH_TANANT_REVIEW_BY_REFERENCE_NO,
      fetchTenant: TActions.FETCH_TENANT,
    }),
    setTenantId() {
      this.tenantId =
        typeof this.$route.params.tenant_id !== 'undefined'
          ? this.$route.params.tenant_id
          : (this.currentTenantReview.tenant_id as string);
    },
    setRouteRefNo() {
      this.currentRefNo = (
        typeof this.$route.params.tenant_ref_id !== 'undefined'
          ? this.$route.params.tenant_ref_id
          : ''
      ) as string;
    },
    async loadTenantReviewByRefNo() {
      this.loading = true;
      this.reviewDetailLoading = true;
      await this.fetchTenantReview(this.$route.params.tenant_ref_id);
    },
    async setTenant() {
      if (this.$route.params.tenantId) {
        await this.fetchTenant(this.tenantId);
        this.tenant = this.getTenant;
      } else {
        this.tenant = this.tenantReview.tenant;
      }
    },
    async loadTenantReviews() {
      // fetch tenant reviews by tenant id
      const id = this.$route.params.tenant_id;
      await this.fetchTenantReviews({ id: id, returnAll: true });
    },
    handleSelectRentalHistory(values) {
      if ((this.breakpoints as any)?.mobile?.matches) {
        this.showingHistory = false;
      }

      if ((this.breakpoints as any)?.desktop?.matches) {
        this.showingHistory = true;
      }

      this.showingInfo = true;

      this.$router.push({
        name: 'view-tenant-reference',
        params: { tenant_ref_id: values.reference_no },
      });
    },
    computeAverageRating() {
      this.averageRating = numberFormat(
        this.tenantReview.tenant.average_rating
      );
    },
    async displayLogo() {
      return (this.currentTenantReview as any)?.user?.business_logo_src;
    },
    async fetchCurrentReviewAvatar() {
      return this.tenant?.user?.avatar_src;
    },
    async fetchAgentAvatar() {
      return (this.currentTenantReview as any)?.user?.avatar_src;
    },
  },
  setup() {
    // show page loading
    store.dispatch(StoreActions.ADD_BODY_CLASSNAME, 'page-loading');
  },
});
